<template>
    <div class="main-content" style="
      padding-bottom: 10vh !important
        background-repeat: no-repeat;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 100%;
        background-position: center;
      ">
        <div class="dashboard-banner position-relative">
            <div class="d-flex justify-content-center align-items-end">
            </div>
            <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
            <div class="px-2 py-1 rounded" style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #add8e6;
                ">
                <p class="mb-0">{{ $t("loading...") }}</p>
            </div>
            </div>
        </div>
        <div class="m-3 mx-3 mt-4">
            <div class="header">
                <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
                <h1 class="main_text text text_main_title" style="margin-right: 18px;">{{ $t("record") }}</h1>
            </div>
            <div class="scrollable-container">
              <div v-if="transferRecord.length > 0">
                <div class="notification-card px-3 py-3" v-for="record in transferRecord" :key="record.id">
                    <div class="row align-items-center">
                        <div class="col-6">
                            <p class="mb-0 title text">{{ $t("balance") }}: {{ record.balance }} ETNEX</p>
                            <span class="date text" style="letter-spacing: normal; color: rgb(127, 127, 127);">
                              
                                <div class="mr-2" style="display: inline-block;">
                                  <i class="fa fa-calendar" aria-hidden="true"></i> {{ formatDate(record.created_at) }}
                                </div>
                            </span>
                        </div>
                        <div class="text-right col-6">
                            <p class="text text_14 weight_600 mb-0" style="line-height: 24px;">
                               {{ record.current }} ETNEX
                            </p>
                        </div>
                    </div>
                </div>
              </div>
              <h3 v-else class="no-data-text text">
                {{ $t("no_data") }}
              </h3>
              <div class="purchase-statement-container" style="text-align: center; padding-top: 10px;" v-if="moreRecords">
                  <button class="make-purchase-button" style="width: 40% !important;" @click="loadMore">
                      {{ $t("load_more") }}
                  </button>
              </div>
            </div>
        </div>
    </div>
  </template>
  <script>
  import { getTransferRecord } from "../../../system/api/api";
  import { handleError } from "../../../system/handleRes";
  import { mapGetters } from "vuex";
  export default {
    components: {
      ...mapGetters(["lang"]),
    },
    data() {
      return {
        currentPage: 1,
        transferRecord: [],
        moreRecords: false,
      };
    },
    mounted() {
      this.loadItems();
    },
  
    methods: {
      
      goBack() {
        this.$router.go(-1)
      },
  
      formatDate(datetime) {
        return datetime.split(' ')[0];
      },
      loadMore() {
        this.currentPage++;
        this.loadItems();
      },
      loadItems(){
        var result = getTransferRecord(
          this.currentPage,
        );
        var self = this;
        result
          .then(function (value) {
            // console.log("API Response:", value);
            self.transferRecord = self.transferRecord.concat(value.data.data.data);
            if (!value.data.data.next_page_url) {
              self.moreRecords = false;
            }else{
              self.moreRecords = true;
            }
            // console.log(self.walletRecord);
  
          })
          .catch(function (error) {
            self.isLoading = false;
            self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          });
      }
    },
  };
  </script>
  
  <style scoped>
  
    .notification-card {
        align-items: center;
        background-color: #1a1a1a;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 7px;
    }
  
  
    .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }

  
  /* text css */
  .text_main_title{
    flex-grow: 1;
    text-align: center;
  }
  
  .no-data-text{
    font-weight: 400;
    font-size: 16px;
    flex-grow: 1;
    text-align: center;
    margin-top: 40px;
    color: var(--Secondary, #7E8088) !important;
  }
  .text{
    color: var(--Main, #FFF);
    font-family: Poppins;
    font-style: normal;
  }
  
  .main_text{
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
    margin-top: 7px;
  }
  .weight_600{
    font-weight: 600;
  }
  
  .text_14{
    font-size: 14px;
  }
  
  /* text css end */
  
  .header {
    display: flex;
    /* padding: 0px 16px; */
    margin-top: 4rem !important;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  
  </style>
  